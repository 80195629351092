<template>
  <modal name="cEdit" height="auto" width="900" :scrollable="true" :clickToClose="false">
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab title="Dados Cadastrais">
            <div class="form">
              <div class="alert remover" v-show="!!errorMessage">
                <a href="#" class="close" @click="errorMessage = null">&times;</a>
                Falha ao cadastrar:<br>
                <p>{{ errorMessage }}</p>
              </div>
              <div class="xrow">
                <div class="col-1-3">
                  <div class="form-group">
                    <label for="">Razão Social</label>
                    <input type="text" class="form-control" readonly disabled v-model="item.razSoc">
                  </div>
                </div>
                <div class="col-1-3" v-show="!!item.fant">
                  <div class="form-group">
                    <label for="">Nome Fantasia</label>
                    <input type="text" class="form-control" readonly disabled v-model="item.fant">
                  </div>
                </div>
                <div class="col-1-3">
                  <div class="form-group">
                    <label>Data de Validação</label>
                    <datetime
                            input-class="form-control"
                            v-model="item.dtValidacao"
                            type="date"
                            placeholder="Data de Validação"
                            :phrases="{ok: 'Confirmar', cancel: 'Fechar'}"
                            value-zone="local"
                    />
                  </div>
                </div>
                <div class="col-1-1">
                  <div class="form-group">
                    <h5>Colaboradores Vinculados:</h5>
                  </div>
                </div>
                <hr>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nome</th>
                      <th>E-mail</th>
                      <th>Telefone</th>
                      <th>Último Acesso</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="colaborador in item.colaboradores" v-bind:key="colaborador.id">
                      <td class="highlight">{{ colaborador.id }}</td>
                      <td class="highlight">{{ colaborador.nome }}</td>
                      <td class="highlight">{{ colaborador.email }}</td>
                      <td class="highlight">{{ colaborador.tel }}</td>
                      <td v-if="colaborador.ultimoLogAcesso[0]" class="highlight">{{ convertDataHora(colaborador.ultimoLogAcesso[0].dataacesso) }}</td>
                      <td v-else class="highlight"></td>
                    </tr>
                    <tr v-if="!item.colaboradores || (item.colaboradores && item.colaboradores.length === 0)">
                      <td colspan="5">
                        Nenhum colaborador vinculado!
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </CTab>
        </CTabs>
      </div>
    </div>
    <div class="modal-footer">
      <button :class="{ 'button button-success': true, 'button-disabled': validation.hasError() }" v-on:click="handleSubmit(false)">
        Alterar
      </button>
      <button class="button" v-on:click="close">Fechar</button>
    </div>
  </modal>
</template>
<script>
import { Validator } from "simple-vue-validator";
import moment from "moment";
import CloseModal from "../../components/CloseModal";

export default {
  name: "cEdit",
  components: { CloseModal },
  props: ["update", "close", "item"],
  validators: {
    "item.dtValidacao": (value) => Validator.value(value).required(),
  },
  data() {
    return {
      filter: "",
      resultLimit: 100,
      activeTab: "dados",
      errorMessage: null,
    };
  },
  computed: {},
  methods: {
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          this.update().catch((error) => {
            this.errorMessage = error.message;
          });
        }
      });
    },
    moment,
    convertDataHora(dataHora = null) {    
        return moment(String(dataHora)).format('DD/MM/YYYY HH:mm')
    },
  },
};
</script>
